import { ComponentType, PropsWithChildren } from 'react';
import { Link as RouterLink, Location } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getStyledUtils, StyledUtilsProps } from '../helpers/StyledUtils';
import Icon, { IconName } from './Icon';

interface Props
  extends StyledUtilsProps,
    Omit<React.HTMLProps<HTMLElement>, 'width' | 'as' | 'muted'> {
  as?: string | ComponentType<any>;
  type?: string;
  target?: string;

  rel?: string;
  to?: string | Partial<Location> | ((location: Location) => Partial<Location> | string);

  primary?: boolean;
  dark?: boolean;
  neutral?: boolean;
  small?: boolean;

  icon?: IconName;
}

const StyledLink = styled.a<Props & { link?: boolean }>(({ theme, link, ...props }) => {
  const styles = css`
    ${getStyledUtils}
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    font-weight: ${theme.font_weigths.medium};
    font-size: ${theme.pxToRem(props.small ? 12 : 13)};
    line-height: ${theme.pxToRem(20)};
    position: relative;

    &:hover {
      span:first-child {
        text-decoration: underline;
      }
    }

    ${theme.optionsToCss(
      {
        primary: css`
          color: ${theme.colors.on.neutral.primary};
        `,
        neutral: css`
          color: ${theme.colors.on.neutral.secondary_neutral};
        `,
        dark: css`
          color: ${theme.colors.on.neutral.primary_neutral};
        `,
      },
      props,
      'primary',
    )};

    &:focus-visible {
      outline: none;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -3px;
        right: -3px;
        bottom: -2px;
        border: 2px solid ${theme.colors.outline.focus.primary};
        border-radius: ${theme.radius.small};
      }
    }
  `;
  return link
    ? css`
        a {
          ${styles}
        }
      `
    : styles;
});

const Link: React.FC<PropsWithChildren<Props>> = ({ to, as, children, icon, target, ...props }) => {
  if (to) {
    return (
      <StyledLink {...props} link as={'span' as any}>
        <RouterLink to={to} target={target}>
          <span>{children}</span>
          {icon && <Icon small icon={icon} right={children ? 1 : 0} />}
        </RouterLink>
      </StyledLink>
    );
  }
  return (
    <StyledLink {...props} as={as || 'a'} target={target}>
      <span>{children}</span>
      {icon && <Icon small icon={icon} right={children ? 1 : 0} />}
    </StyledLink>
  );
};

export default Link;
