import { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

import Alert from './base/Alert';
import Button, { ClickableArea } from './base/Button';
import Divider from './base/Divider';
import Icon, { IconName } from './base/Icon';
import Loading from './base/Loading';
import Skeleton from './base/Skeleton';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';
import Tooltip from './base/Tooltip';

type ColumnWidth = number | { max?: number; min?: number };

const StyledHeaders = styled.div<{ nested?: boolean; full_page: boolean }>(
  ({ theme, nested, full_page }) => css`
    border-bottom: ${theme.border};
    background-color: ${theme.colors.surface.base.variant_surface_2};
    display: flex;
    ${full_page &&
    css`
      z-index: 4;
      position: sticky;
      top: ${theme.pxToRem(57)};
      bottom: ${theme.pxToRem(44)};
    `}

    ${nested &&
    css`
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: -1px;
        left: 0;
        z-index: 2;
        border: 2px solid ${theme.colors.outline.focus.primary};
      }
    `}
  `,
);

const StyledCell = styled.div<{ size: ColumnWidth; header: boolean }>(
  ({ theme, size, header }) => css`
    ${typeof size === 'object'
      ? css`
          display: flex;
          align-items: center;
          flex-grow: 1;
          ${size.min &&
          css`
            min-width: ${size.min}px;
          `}
          ${size.max &&
          css`
            max-width: ${size.max}px;
          `}
        `
      : css`
          flex-basis: ${size * 100}%;
        `}

    padding: ${theme.spacing(header ? 1 : 2)} ${theme.spacing(2)};
    &:first-child {
      padding-left: ${theme.spacing(4)};
    }
    &:not(:last-child) {
      border-right: ${({ theme }) => theme.border};
    }
  `,
);

const StyledFooter = styled.div<{ fill?: boolean }>`
  ${({ theme, fill }) => css`
    ${fill &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    `}
    display: flex;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    border-top: ${theme.border};
    background-color: ${theme.colors.surface.base.surface};
  `}
`;

const StyledTable = styled.div<{ full_page: boolean }>`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.base.surface};

  ${({ full_page, theme }) =>
    full_page &&
    css`
      ${StyledFooter} {
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 2;
        box-shadow: ${theme.elevation[3]};
      }
    `}
`;

const StyledField = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:disabled {
    color: unset;
    cursor: default;
  }
`;

const StyledSelectableRow = styled.div<{
  selected?: boolean;
  highlighted?: boolean;
  outdated?: boolean;
  selectable?: boolean;
  full_page?: boolean;
}>(
  ({ theme, selected, selectable, highlighted, outdated, full_page }) => css`
    position: relative;
    overflow: visible;
    display: flex;
    background-color: ${theme.colors.surface.base.surface};

    border-bottom: ${theme.border};
    &:last-child {
      // Display hack to hide the border on the last row when next to the footer
      transform: translateY(1px);
      margin-top: -1px;
    }
    ${selectable &&
    css`
      cursor: pointer;
      &:hover {
        &:not(:has(button:hover, a:hover)) {
          background-color: ${theme.colors.surface.base.hover.neutral};
        }
      }
    `}
    ${outdated &&
    !selected &&
    css`
      opacity: 0.6;
    `}
    ${highlighted &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: -1px;
        left: 0;
        z-index: 2;
        border: 2px solid ${theme.colors.outline.focus.primary};
      }
    `}
    ${selected &&
    css`
      z-index: 3;
      ${full_page &&
      css`
        position: sticky;
        top: ${theme.pxToRem(59 + 28)};
        bottom: ${theme.pxToRem(44)};
      `}
      &:after {
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: 0;
        left: 0;
        z-index: ${theme.zindex.fixed};
        border: 3px solid ${theme.colors.outline.focus.primary};
        pointer-events: none;
      }
    `}
  `,
);

const StyledLoadingOverlay = styled.div<{ full_page: boolean }>`
  position: absolute;
  top: ${({ theme, full_page }) => (full_page ? theme.spacing(6) : 0)};
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.surface.base.surface};
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const StyledLoadPrompt = styled(ClickableArea)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.surface.container.primary};
  border-bottom: ${({ theme }) => theme.border};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(3)}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.container.hover.primary};
  }
`;

type CustomField = { element: JSX.Element; clickable_area: boolean };

type Field = string | number | boolean | JSX.Element | CustomField;

interface Row {
  id: string | number | React.ReactText;
  selected?: boolean;
  selectable?: boolean;
  highlighted?: boolean;
  outdated?: boolean;
  fields?: Field[];
  element?: React.ReactNode;
  has_error?: boolean;
  expendable?: boolean;
}

interface NestedTable {
  headers: Header[];
  rows: Row[];
}

type Header = string | JSX.Element;

type onRowSelected = (row_id: string | null) => void;

interface Props {
  fill?: boolean;
  raised?: boolean;
  headers: Header[];
  rows: (Row | NestedTable)[];
  widths: ColumnWidth[];
  current_count?: number;
  has_new_results?: boolean;
  alert_top?: string;
  bottom_element?: React.ReactNode;
  loading?: boolean;
  loading_title?: string;
  loading_message?: string;
  total_count?: number | null;
  onLoadNewResults?(): void;
  onNextPage?(): void;
  onPreviousPage?(): void;
  onRowSelected?: onRowSelected;
}

const TableField: React.FC<{ field: Field }> = ({ field }) => {
  if (typeof field === 'string' || typeof field === 'number' || typeof field === 'boolean') {
    return (
      <StyledField style={{ overflow: 'hidden' }}>
        <Text ellipsis size="s">
          {field}
        </Text>
      </StyledField>
    );
  }

  if (field === null || field === undefined) {
    return (
      <StyledField>
        <Text muted size="s">
          –
        </Text>
      </StyledField>
    );
  }

  if (typeof field === 'object' && (field as any).element) {
    return <StyledField>{(field as CustomField).element}</StyledField>;
  }

  return <StyledField>{field as any}</StyledField>;
};

const TableRow: React.FC<{
  row: Row;
  widths: ColumnWidth[];
  onRowSelected?: onRowSelected;
  nested: boolean;
  full_page: boolean;
}> = ({ row, full_page, widths, onRowSelected, nested }) => {
  const selectable = row.selectable === false ? false : !!onRowSelected;
  let icon: IconName = row.highlighted ? 'expand_more' : 'chevron_right';
  if (row.outdated) {
    icon = 'visibility_off';
  }
  return (
    <StyledSelectableRow
      key={row.id}
      selectable={selectable}
      highlighted={row.highlighted}
      outdated={!nested && row.outdated}
      selected={row.selected}
      full_page={full_page}>
      {row.element
        ? row.element
        : row.fields?.map((field, i) => (
            <StyledCell
              key={i}
              size={widths[i]}
              header={false}
              onClick={() => selectable && !!onRowSelected && onRowSelected(row.id as string)}>
              {onRowSelected &&
                i === 0 &&
                !nested &&
                (row.expendable ? (
                  row.has_error ? (
                    <Div m={{ l: -1.5, y: -1.5, r: 1.5 }} p={1.5}>
                      <Icon
                        muted={row.outdated}
                        danger={!row.outdated}
                        icon={row.outdated ? 'visibility_off' : 'error'}
                      />
                    </Div>
                  ) : (
                    <Tooltip
                      disabled={!row.outdated}
                      placement="bottom-start"
                      tooltip="This row no longer matches your current filter."
                      offset={[-6, 6]}>
                      <ClickableArea
                        m={{ l: -1.5, y: -1.5, r: 1.5 }}
                        style={{ width: 'auto' }}
                        p={1.5}
                        rounded
                        onClick={(e) => {
                          e.stopPropagation();
                          row.highlighted ? onRowSelected(null) : onRowSelected(row.id as string);
                        }}>
                        <Icon muted={row.outdated} danger={!!row.has_error} icon={icon} />
                      </ClickableArea>
                    </Tooltip>
                  )
                ) : null)}
              {onRowSelected && i === 0 && nested && <Icon icon="subdirectory" small left={3} />}
              <TableField field={field} />
            </StyledCell>
          ))}
    </StyledSelectableRow>
  );
};

const Table: React.FC<Props> = ({
  headers,
  rows,
  widths,
  has_new_results,
  onLoadNewResults,
  onRowSelected,
  current_count,
  onNextPage,
  onPreviousPage,
  fill,
  alert_top,
  bottom_element,
  loading,
  loading_title,
  loading_message,
  total_count,
}) => {
  const [cached_rows, setCachedRows] = useState(rows);
  const [cached_count, setCachedCount] = useState(current_count);

  useEffect(() => {
    if (!loading) {
      setCachedRows(rows);
    }
  }, [rows, loading]);

  useEffect(() => {
    if (!loading) {
      setCachedCount(current_count);
    }
  }, [current_count, loading]);

  return (
    <StyledTable full_page={fill || false}>
      {headers?.length > 0 && (
        <StyledHeaders full_page={fill || false}>
          {headers.map((header, i) => (
            <StyledCell key={i} size={widths[i]} header>
              {typeof header === 'string' ? (
                <StyledField as="div">
                  <Text subtitle muted size="s" as="span">
                    {header}
                  </Text>
                </StyledField>
              ) : (
                <StyledField as="div">{header}</StyledField>
              )}
            </StyledCell>
          ))}
        </StyledHeaders>
      )}
      {alert_top && (
        <>
          <Alert warning>{alert_top}</Alert>
          <Divider />
        </>
      )}
      {has_new_results && onLoadNewResults && (
        <StyledLoadPrompt primary onClick={() => onLoadNewResults()}>
          <Icon icon="autorenew" small left={1} />
          <Text subtitle size="s" primary>
            Load newest...
          </Text>
        </StyledLoadPrompt>
      )}
      <Div flex={{ direction: 'column', grow: true }} style={{ position: 'relative' }}>
        {loading ? (
          cached_rows.length !== 0 ? (
            <StyledLoadingOverlay full_page={fill || false}>
              <Loading />
            </StyledLoadingOverlay>
          ) : (
            <Div p={{ y: 12 }} flex={{ direction: 'column', grow: true, justify: 'center' }}>
              <Loading loading_title={loading_title} loading_message={loading_message} />
            </Div>
          )
        ) : null}
        {!loading && rows.length === 0 && (
          <Text size="s" muted p={{ all: 4 }}>
            There is nothing to show!
          </Text>
        )}
        {cached_rows.map((row) =>
          'headers' in row ? (
            <Fragment key={'nested'}>
              <StyledHeaders nested full_page={false}>
                {row.headers.map((header, i) => (
                  <StyledCell key={i} size={widths[i]} header>
                    {typeof header === 'string' ? (
                      <StyledField as="div">
                        <Text subtitle muted size="s" as="span">
                          {header}
                        </Text>
                      </StyledField>
                    ) : (
                      <StyledField as="div">{header}</StyledField>
                    )}
                  </StyledCell>
                ))}
              </StyledHeaders>
              {row.rows.map((row) => (
                <TableRow
                  nested
                  key={row.id}
                  row={row}
                  widths={widths}
                  onRowSelected={onRowSelected}
                  full_page={fill || false}
                />
              ))}
            </Fragment>
          ) : (
            <TableRow
              nested={false}
              key={row.id}
              row={row}
              widths={widths}
              onRowSelected={onRowSelected}
              full_page={fill || false}
            />
          ),
        )}
      </Div>
      {bottom_element}
      {((cached_count && cached_count >= 0) || onPreviousPage || onNextPage) && (
        <StyledFooter fill={fill}>
          <Div flex={{ grow: true, justify: 'space-between' }}>
            <Div flex={{ align: 'center', grow: true }}>
              {total_count === null && <Skeleton width={8} xsmall />}
              {total_count !== null && (
                <Text as="span">
                  Viewing {cached_count || cached_rows.length}{' '}
                  {total_count && (
                    <>
                      of{' '}
                      <NumberFormat
                        renderText={(v) => v}
                        displayType={'text'}
                        value={total_count}
                        thousandSeparator={','}
                      />
                    </>
                  )}
                </Text>
              )}
            </Div>
            <Div flex={{ align: 'center', gap: 2 }}>
              <Button
                icon="chevron_left"
                minimal
                disabled={!onPreviousPage || loading}
                onClick={onPreviousPage ? onPreviousPage : undefined}
                small
              />
              <Button
                icon="chevron_right"
                minimal
                disabled={!onNextPage || loading}
                onClick={onNextPage ? onNextPage : undefined}
                small
              />
            </Div>
          </Div>
        </StyledFooter>
      )}
    </StyledTable>
  );
};

export default Table;
