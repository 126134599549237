import { useEffect, useState } from 'react';

import { ThemeModes } from './themes';

export const useThemeMode = (): [ThemeModes, (theme: ThemeModes) => void] => {
  const [theme, setTheme] = useState<ThemeModes>('light');
  const changeTheme = (theme: ThemeModes) => {
    window.localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  useEffect(() => {
    window.document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme') as ThemeModes;
    if (!localTheme) {
      const darkThemeMq = window?.matchMedia('(prefers-color-scheme: dark)');
      setTheme(darkThemeMq.matches ? 'dark' : 'light');
    } else {
      setTheme(localTheme);
    }
  }, []);

  return [theme, changeTheme];
};
