import { Location } from 'history';
import React, { Children, ComponentType, FC, forwardRef, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DropdownMenu, { DropdownMenuOption } from '../DropdownMenu';
import { Div, getStyledUtils, StyledUtilsProps } from '../helpers/StyledUtils';
import Icon, { IconName } from './Icon';
import { StyledDropdown } from '../Dropdown';
import Tooltip from './Tooltip';
import { useAuthorized, usePermissionAwareDisabled } from '../../contexts/TeamPermissionContext';
import { TeamRole } from '../../../../../../typings/TeamMember.interface';
import { withStaticProperties } from '../../../utils/withStaticProperties';
import Badge from './Badge';

interface Props
  extends StyledUtilsProps,
    Omit<React.HTMLProps<HTMLElement>, 'width' | 'as' | 'muted'> {
  as?: string | ComponentType<any>;
  to?: string | Partial<Location> | ((location: Location) => Partial<Location> | string);

  primary?: boolean;
  danger?: boolean;
  outline?: boolean;
  minimal?: boolean;
  on?: 'primary' | 'danger' | 'neutral' | 'success' | 'warning' | 'background';

  icon?: IconName;
  icon_muted?: boolean;
  dropdown?: boolean;

  small?: boolean | { text: 's' | 'm' };

  badge?: {
    label: string;
  };

  skeleton?: boolean;

  block?: boolean;

  disabled?: boolean;
  submit?: boolean;
  loading?: boolean;
}

interface StyledProps extends Omit<Props, 'onClick' | 'to' | 'submit'> {
  link?: boolean;
}

export const StyledButton = styled.button<StyledProps>(
  ({ theme, link, disabled, small, ...props }) => {
    const is_disabled = disabled || !!props['aria-disabled'];

    const styles = css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      border: none;
      text-decoration: none;
      box-sizing: border-box;
      position: relative;

      border-radius: ${theme.radius.normal};
      font-weight: ${theme.font_weigths.medium};
      font-size: ${theme.pxToRem(
        small && typeof small === 'object' && small.text === 's' ? 12 : 13,
      )};
      line-height: ${theme.pxToRem(20)};
      padding: ${theme.spacing(small ? 1 : 1.5)} ${theme.spacing(small ? 2 : 3)};

      ${props.block &&
      css`
        width: 100%;
      `};

      &:focus-visible {
        &::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }

      ${!props.outline &&
      !props.minimal &&
      theme.optionsToCss(
        {
          primary: css`
            color: ${theme.colors.on.hue.primary};
            background-color: ${theme.colors.surface.base.primary};
            box-shadow: ${theme.elevation[2]};
            svg {
              fill: ${theme.colors.on.hue.primary};
            }
            &:hover {
              background-color: ${theme.colors.surface.base.hover.primary};
            }
            &:active {
              box-shadow: none;
              background-color: ${theme.colors.surface.base.active.primary};
            }
          `,
          danger: css`
            color: ${theme.colors.on.hue.danger};
            background-color: ${theme.colors.surface.base.danger};
            box-shadow: ${theme.elevation[2]};
            svg {
              fill: ${theme.colors.on.hue.danger};
            }
            &:hover {
              background-color: ${theme.colors.surface.base.hover.danger};
            }
            &:active {
              box-shadow: none;
              background-color: ${theme.colors.surface.base.active.danger};
            }
          `,
        },
        props,
        'primary',
      )}

      ${(props.outline || props.minimal) &&
      theme.optionsToCss(
        {
          primary: css`
            color: ${theme.colors.on.neutral.primary};
          `,
          secondary: css`
            color: ${theme.colors.on.neutral.primary_neutral};
          `,
          danger: css`
            color: ${theme.colors.on.neutral.danger};
          `,
        },
        props,
        'secondary',
      )}

    ${props.minimal &&
      css`
        background-color: transparent;
        svg {
          fill: ${theme.colors.on.neutral.primary_neutral};
        }

        &:hover {
          background-color: ${props.on
            ? props.on === 'background'
              ? theme.colors.surface.base.hover.neutral_variant
              : theme.colors.surface.container.hover[props.on]
            : theme.colors.surface.base.hover.neutral};
        }
        &:active {
          background-color: ${props.on
            ? theme.colors.surface.container.active[props.on]
            : theme.colors.surface.base.active.neutral};
        }
      `}

    ${props.outline &&
      css`
        background-color: ${theme.colors.surface.base.surface};
        box-shadow: ${theme.elevation[1]};
        svg {
          fill: ${theme.colors.on.neutral.primary_neutral};
        }
        &:hover {
          background-color: ${theme.colors.surface.base.hover.neutral};
        }
        &:active {
          box-shadow: none;
          background-color: ${theme.colors.surface.base.active.neutral};
        }
        // Set border with absolute ::after to maintain alignment
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid ${theme.colors.outline.neutral};
          border-radius: ${theme.radius.normal};
        }
      `}

    ${is_disabled &&
      css`
        cursor: not-allowed;
        color: ${theme.colors.on.neutral.disabled};
        background-color: transparent;
        box-shadow: none;

        svg {
          fill: ${theme.colors.on.neutral.disabled};
        }
        &:hover {
          background-color: transparent;
        }
        ${!props.minimal &&
        css`
          background-color: ${theme.colors.surface.base.disabled};
          box-shadow: ${theme.elevation[1]};
          &:hover {
            background-color: ${theme.colors.surface.base.disabled};
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid ${theme.colors.outline.neutral};
            border-radius: ${theme.radius.normal};
          }
        `}
      `};
    `;

    return link
      ? css`
          ${(props) => getStyledUtils(props)}
          ${props.block &&
          css`
            width: 100%;
          `};
          a {
            ${styles}
          }
        `
      : css`
          ${(props) => getStyledUtils(props)}
          ${styles}
        `;
  },
);

const StyledButtonGroup = styled(Div)(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    border-radius: ${theme.radius.normal};
    box-shadow: ${theme.elevation[1]};

    > div {
      height: 100%;
    }
    > div:first-child {
      flex-grow: 1;
    }
  `,
);

const StyledButtonInGroup = styled(Div)<{ first: boolean; last: boolean }>(
  ({ theme, first, last }) => css`
    ${StyledButton}:first-of-type:not(${StyledDropdown} *), ${StyledClickableArea}:first-of-type:not(${StyledDropdown} *) {
      height: 100%;
      border: none;
      width: 100%;
      box-shadow: none;

      &:focus-visible {
        z-index: 1;
      }

      ${first &&
      !last &&
      css`
        border-radius: ${theme.radius.normal_inset} 0 0 ${theme.radius.normal_inset};
        &::after {
          border-radius: ${theme.radius.normal_inset} 0 0 ${theme.radius.normal_inset};
        }
        &:focus-visible {
          &::before {
            border-radius: ${theme.radius.normal} 0 0 ${theme.radius.normal};
          }
        }
      `}
      ${last &&
      !first &&
      css`
        border-radius: 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0;
        &::after {
          border-left: none;
          border-radius: 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0;
        }
        &:focus-visible {
          &::before {
            border-radius: 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0;
          }
        }
      `}
      ${!first &&
      !last &&
      css`
        border-radius: 0;
        &::after {
          border-radius: 0;
          border-left: none;
        }
        &:focus-visible {
          &::before {
            border-radius: 0;
          }
        }
      `};
    }
  `,
);

interface ButtonGroupProps extends StyledUtilsProps {
  more_options?: DropdownMenuOption[];
}

export const ButtonGroup: React.FC<PropsWithChildren<ButtonGroupProps>> = ({
  children,
  more_options,
  ...props
}) => {
  const childs = Children.toArray(children);
  return (
    <StyledButtonGroup {...props}>
      {childs.map((child, i) => {
        return (
          <StyledButtonInGroup
            key={i}
            first={i === 0}
            last={more_options ? false : i === childs.length - 1}>
            {child}
          </StyledButtonInGroup>
        );
      })}
      {more_options && (
        <StyledButtonInGroup first={false} last={true}>
          <DropdownMenu options={more_options} outline p={0} placement="bottom-end" />
        </StyledButtonInGroup>
      )}
    </StyledButtonGroup>
  );
};

export const StyledIconButton = styled(StyledButton)(({ theme, link, small }) => {
  const styles = css`
    padding: ${theme.spacing(small ? 1.5 : 2)};
    border-radius: ${theme.radius.normal};

    a {
      display: block;
    }
  `;
  return link
    ? css`
        a {
          ${styles}
        }
      `
    : styles;
});

const Button = React.forwardRef<any, PropsWithChildren<Props>>(
  ({ as, to, submit, children, icon, dropdown, badge, icon_muted, ...props }, ref) => {
    if (to && !props.disabled) {
      if (icon && !children) {
        return (
          <StyledIconButton ref={ref} {...props} link as={'div' as any}>
            <Link to={to}>
              <Icon icon={icon} muted={icon_muted} />
            </Link>
          </StyledIconButton>
        );
      }

      if (!icon && !children && dropdown) {
        return (
          <StyledIconButton ref={ref} {...props} link as={'div' as any}>
            <Link to={to}>
              <Icon icon={'expand_more'} />
            </Link>
          </StyledIconButton>
        );
      }

      return (
        <StyledButton ref={ref} {...props} link as={'div' as any}>
          <Link to={to}>
            {icon && <Icon icon={icon} left={2} />}
            {children}
            {dropdown && <Icon icon={'expand_more'} right={2} style={{ marginLeft: 'auto' }} />}
          </Link>
        </StyledButton>
      );
    }

    /**
     * There's a browser quirk where disabled elements don't fire events. This affects tooltips
     * where tooltips around disabled buttons won't disappear. Therefore, instead of setting "disabled"
     * prop, we're using "aria-disabled" and manually disabling the button.
     *
     * @see https://github.com/facebook/react/issues/18753
     * @see https://floating-ui.com/docs/react#disabled-elements
     */
    const disabled_props = props.disabled
      ? {
          disabled: false,
          'aria-disabled': true,
          onClick: (event: React.MouseEvent) => event.preventDefault(),
          onDoubleClick: (event: React.MouseEvent) => event.preventDefault(),
        }
      : {};

    if (icon && !children) {
      return (
        <StyledIconButton
          ref={ref}
          {...props}
          {...disabled_props}
          as={as || 'button'}
          type={submit ? 'submit' : 'button'}>
          <Icon icon={icon} muted={icon_muted} />
        </StyledIconButton>
      );
    }

    if (!icon && !children && dropdown) {
      return (
        <StyledIconButton
          ref={ref}
          {...props}
          {...disabled_props}
          as={as || 'button'}
          type={submit ? 'submit' : 'button'}>
          <Icon icon={'expand_more'} />
        </StyledIconButton>
      );
    }

    return (
      <StyledButton
        ref={ref}
        {...props}
        {...disabled_props}
        as={as || 'button'}
        type={submit ? 'submit' : 'button'}>
        {icon && <Icon icon={icon} left={2} />}
        {children}
        {badge && (
          <Badge subtle m={{ l: 2 }}>
            {badge.label}
          </Badge>
        )}
        {dropdown && (
          <Icon
            muted
            icon={'expand_more'}
            right={2}
            style={props.block ? { marginLeft: 'auto' } : {}}
          />
        )}
      </StyledButton>
    );
  },
);

const ButtonPermission = forwardRef<
  any,
  React.ComponentPropsWithRef<typeof Button> & { role?: TeamRole }
>(({ role, ...buttonProps }, ref) => {
  const authorized = useAuthorized(role);
  if (authorized) return <Button ref={ref} {...buttonProps} />;

  // if already disabled then we don't need to show the tooltip
  if (buttonProps.disabled) return <Button ref={ref} {...buttonProps} />;

  return (
    <Tooltip tooltip="You don't have permission to perform this action. Contact your admin for access.">
      <Button ref={ref} {...buttonProps} disabled={buttonProps.disabled || !authorized} />
    </Tooltip>
  );
});

export interface ClickableAreaProps
  extends StyledUtilsProps,
    Omit<React.HTMLProps<HTMLButtonElement>, 'width' | 'as'> {
  as?: string | ComponentType<any>;
  to?: string | Partial<Location> | ((location: Location) => Partial<Location> | string);

  on?: 'background';
  danger?: boolean;
  primary?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  submit?: boolean;
  block?: boolean;
  highlight?: boolean;

  linkOnClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const StyledClickableArea = styled.button<ClickableAreaProps & { link?: boolean; block?: boolean }>(
  ({ theme, rounded, link, block, disabled, highlight, on, ...props }) => {
    const styles = css`
      ${(props) => getStyledUtils(props)}

      display: block;
      width: ${block ? '100%' : 'auto'};
      padding: 0;
      box-sizing: border-box;
      text-align: left;
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      background-color: unset;
      border: none;
      border-radius: none;
      text-decoration: none;
      color: unset;
      position: relative;

      ${!disabled &&
      css`
        &:hover {
          background-color: ${on === 'background'
            ? theme.colors.surface.base.hover.neutral_variant
            : theme.colors.surface.base.hover.neutral};
        }
      `}

      ${highlight &&
      css`
        background-color: ${on === 'background'
          ? theme.colors.surface.base.hover.neutral_variant
          : theme.colors.surface.base.hover.neutral};
      `}

      &:focus-visible {
        outline: none;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }

      ${rounded &&
      css`
        border-radius: ${theme.radius.normal};
      `}

      ${theme.optionsToCss(
        {
          danger: css`
            color: ${theme.colors.on.hue_container.danger};
          `,
          primary: css`
            color: ${theme.colors.on.hue_container.primary};
          `,
        },
        props,
      )}

      &:disabled {
        cursor: not-allowed;
        box-shadow: none;
      }
    `;
    return link
      ? css`
          a {
            ${styles}
            ${disabled &&
            css`
              pointer-events: none;
            `}
          }
        `
      : styles;
  },
);

const ClickableAreaComponent: FC<PropsWithChildren<ClickableAreaProps>> = ({
  as,
  to,
  submit,
  block = true,
  children,
  linkOnClick,
  ...props
}) => {
  if (to) {
    return (
      <StyledClickableArea
        {...props}
        block={block}
        link
        type={submit ? 'submit' : 'button'}
        as={'div' as any}>
        <Link onClick={linkOnClick} to={to}>
          {children}
        </Link>
      </StyledClickableArea>
    );
  }
  return (
    <StyledClickableArea
      {...props}
      block={block}
      as={as || 'button'}
      type={submit ? 'submit' : 'button'}>
      {children}
    </StyledClickableArea>
  );
};

const ClickableAreaPermission: React.FC<
  React.ComponentPropsWithRef<typeof ClickableAreaComponent> & { role?: TeamRole }
> = ({ role, ...clickableAreaProps }) => {
  const disabled = usePermissionAwareDisabled(clickableAreaProps.disabled, role);

  return <ClickableAreaComponent {...clickableAreaProps} disabled={disabled} />;
};

export const ClickableArea = withStaticProperties(ClickableAreaComponent, {
  Permission: ClickableAreaPermission,
});

const StyledSelectButton = styled(Button)<{ minimal?: boolean; muted?: boolean }>(
  ({ theme, minimal, muted }) => css`
    background-color: transparent;
    box-shadow: none;
    color: ${theme.colors.on.neutral.primary_neutral};
    justify-content: flex-start;

    ${muted &&
    css`
      color: ${theme.colors.on.neutral.secondary_neutral};
    `}

    ${minimal &&
    css`
      &:after {
        border: none !important;
      }
    `}

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid ${theme.colors.outline.neutral};
      border-radius: ${theme.radius.normal};
    }

    &:hover,
    a:hover {
      background-color: transparent;
      &::after {
        border: 1px solid ${theme.colors.outline.hover.neutral};
      }
    }
  `,
);

interface SelectButtonProps
  extends StyledUtilsProps,
    Omit<React.HTMLProps<HTMLElement>, 'width' | 'as' | 'muted' | 'ref'> {
  icon?: IconName;
  block?: boolean;
  minimal?: boolean;
  muted?: boolean;
}

export const SelectButton: React.FC<PropsWithChildren<SelectButtonProps>> = (props) => {
  return <StyledSelectButton dropdown {...props} />;
};

export const StyledPlaceholderButton = styled(Button)(({ theme, disabled, ...props }) => {
  return css`
    background-color: transparent;
    box-shadow: none;
    color: ${theme.colors.on.neutral.secondary_neutral};
    justify-content: center;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px dashed ${theme.colors.outline.neutral};
      border-radius: ${theme.radius.normal};
    }

    &:hover,
    a:hover {
      background-color: transparent;
      color: ${theme.colors.on.neutral.primary};
      &::after {
        border: 1px dashed ${theme.colors.on.neutral.primary};
      }
    }

    ${disabled &&
    css`
      &:hover {
        color: ${theme.colors.on.neutral.secondary_neutral};
        &::after {
          border: 1px dashed ${theme.colors.outline.neutral};
        }
      }
    `}
  `;
});

interface PlaceholderButtonProps
  extends StyledUtilsProps,
    Omit<React.HTMLProps<HTMLElement>, 'width' | 'as' | 'muted' | 'ref'> {}

const PlaceholderButtonComponent: React.FC<PropsWithChildren<PlaceholderButtonProps>> = (props) => {
  return <StyledPlaceholderButton icon="add_circle" {...props} />;
};

const PlaceholderButtonPermission: React.FC<
  React.ComponentPropsWithRef<typeof PlaceholderButtonComponent> & { role?: TeamRole }
> = ({ role, ...props }) => {
  const disabled = usePermissionAwareDisabled(props.disabled, role);

  return <PlaceholderButtonComponent {...props} disabled={disabled} />;
};

export const PlaceholderButton = withStaticProperties(PlaceholderButtonComponent, {
  Permission: PlaceholderButtonPermission,
});

export default withStaticProperties(Button, { Permission: ButtonPermission });
