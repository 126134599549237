import { useEffect, useMemo, useState } from 'react';
import {
  FlattenSimpleInterpolation,
  ThemeProvider as SCThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components';
import styledNormalize from 'styled-normalize';

import getThemes, { Theme, ThemeModes, ThemeOverrides } from './themes';
import { useThemeMode } from './useThemeMode';

type Props = {
  overrides?: ThemeOverrides;
  getGlobalStyles?: (theme: Theme) => FlattenSimpleInterpolation;
  children: React.ReactNode;
  is_shadow_node?: boolean;
};

const defaultGetGlobalStyles =
  (is_shadow_node?: boolean) => (theme: Theme & { mode: 'dark' | 'light' }) => css`
    ${is_shadow_node &&
    css`
      .material-symbols-rounded {
        font-family: 'Material Symbols Rounded';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
      }
    `}
    html {
      font-size: ${theme.font_size};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    ${theme.mode === 'dark' &&
    css`
      :root {
        color-scheme: dark;
      }
    `}

    html {
      font-style: normal;
      font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'avenir next',
        avenir,
        helvetica,
        'helvetica neue',
        ubuntu,
        roboto,
        noto,
        'segoe ui',
        arial,
        sans-serif;
      line-height: 1.45;

      color: ${theme.colors.on.neutral.primary_neutral};

      padding: 0;
      margin: 0;
      background-color: ${is_shadow_node ? 'transparent' : theme.colors.surface.base.background};
    }

    p {
      margin-top: 0;
    }

    code,
    pre,
    kbd {
      font-family:
        JetBrains Mono,
        monospace;
    }

    strong {
      font-size: unset;
    }

    code {
      background: ${theme.colors.surface.container.neutral};
      color: ${theme.colors.on.neutral.danger};
      padding: 0 ${theme.spacing(1)};
      font-weight: 500;
      border-radius: ${theme.radius.normal};
    }

    pre {
      border-radius: ${theme.radius.normal};
      background-color: ${theme.colors.surface.base.background};
      padding: ${theme.spacing(2)};
    }

    button,
    select,
    [type='button'],
    [type='reset'],
    [type='submit'] {
      appearance: none;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
    }

    label {
      display: block;
      margin-bottom: ${theme.spacing(1)};
      font-size: ${theme.pxToRem(12)};
      line-height: ${theme.pxToRem(20)};
      font-weight: ${theme.font_weigths.medium};
    }

    input,
    textarea,
    select {
      outline: none;
      border: ${theme.border};
      background-color: ${theme.colors.surface.base.surface};
      border-radius: ${theme.radius.normal};
      padding: ${theme.spacing(3)};

      &:focus {
        outline: 2px solid ${theme.colors.outline.focus.primary};
      }
    }

    select {
      background-position: left center;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.80667 10.4733L7.53333 12.2C7.79333 12.46 8.21333 12.46 8.47333 12.2L10.2 10.4733C10.62 10.0533 10.32 9.33334 9.72667 9.33334H6.27333C5.68 9.33334 5.38667 10.0533 5.80667 10.4733Z'
        fill='${encodeURIComponent(theme.colors.on.neutral.primary_neutral)}'
        /%3E%3Cpath d='M5.80667 5.52668L7.53333 3.80001C7.79333 3.54001 8.21333 3.54001 8.47333 3.80001L10.2 5.52668C10.62 5.94668 10.32 6.66668 9.72667 6.66668H6.27333C5.68 6.66668 5.38667 5.94668 5.80667 5.52668Z'
        fill='${encodeURIComponent(theme.colors.on.neutral.primary_neutral)}'/%3E%3C/svg%3E%0A")
        right center no-repeat;
    }
  `;

const WrapGlobalStyle = createGlobalStyle<{ getGlobalStyles: any }>(
  ({ theme, ...props }: { theme }) => css`
    ${styledNormalize}
    ${(props as any).getGlobalStyles(theme)}
  `,
);

const ThemeProvider: React.FC<Props> = ({
  children,
  overrides,
  getGlobalStyles,
  is_shadow_node,
}) => {
  const [mode, setMode] = useThemeMode();
  const toggleTheme = (theme?: ThemeModes) => {
    if (theme) {
      return setMode(theme);
    }
    return setMode(mode === 'light' ? 'dark' : 'light');
  };

  const [mounted, setMounted] = useState(false);

  const themes = useMemo(() => getThemes(overrides, is_shadow_node), [overrides]);

  useEffect(() => {
    if (!mounted && mode) {
      setMounted(true);
    }
  }, [mode]);

  const body = (
    <SCThemeProvider theme={{ ...themes[mode], mode, toggleTheme }}>
      <WrapGlobalStyle
        getGlobalStyles={getGlobalStyles || defaultGetGlobalStyles(is_shadow_node)}
      />
      {children}
    </SCThemeProvider>
  );
  return <div style={{ visibility: !mounted ? 'hidden' : 'visible' }}>{body}</div>;
};

export default ThemeProvider;
