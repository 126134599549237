const WEBSITE_URL = process.env.WEBSITE_URL || `https://${process.env.ROOT_DOMAIN}`;

const LINKS = {
  product_docs: {
    root: `${WEBSITE_URL}/docs/`,
    connection_guide: `${WEBSITE_URL}/guides/guide-to-creating-your-hookdeck-connections`,
    providers: `${WEBSITE_URL}/docs/requirements#supported-sources`,
    cli: `${WEBSITE_URL}/docs/using-the-cli`,
    cli_install: `${WEBSITE_URL}/docs/using-the-cli#getting-started-with-the-cli`,
    filters: `${WEBSITE_URL}/docs/filters`,
    sources: `${WEBSITE_URL}/docs/sources`,
    destinations: `${WEBSITE_URL}/docs/destinations`,
    rules: `${WEBSITE_URL}/docs/rules`,
    update_connection: `${WEBSITE_URL}/docs/edit-a-connection`,
    bulk_retry: `${WEBSITE_URL}/docs/manually-retry-events#retry-many-events`,
    signature_verification: `${WEBSITE_URL}/docs/signature-verification#verify-the-hookdeck-signature`,
    request_retries: `${WEBSITE_URL}/docs/requests#retrying-a-request`,
    ignored_events: `${WEBSITE_URL}/docs/requests#ignored-events`,
    metrics: `${WEBSITE_URL}/docs/metrics`,
  },
  landing_pages: {
    monitor: `${WEBSITE_URL}/monitor`,
    recover: `${WEBSITE_URL}/recover`,
    manage: `${WEBSITE_URL}/manage`,
    production: `${WEBSITE_URL}/production`,
  },
  guides: `${WEBSITE_URL}/guides`,
  landing: `${WEBSITE_URL}`,
  pricing: `${WEBSITE_URL}/pricing`,
  privacy: `${WEBSITE_URL}/privacy`,
  terms: `${WEBSITE_URL}/terms`,
  docs: `${WEBSITE_URL}/docs`,
  api_ref: `${WEBSITE_URL}/docs/api`,
  changelog: 'https://changelog.hookdeck.com',
  roadmap: 'https://roadmap.hookdeck.com',
  mock_api: 'https://mock.hookdeck.com',
  community:
    'https://join.slack.com/t/hookdeckdevelopers/shared_invite/zt-yw7hlyzp-EQuO3QvdiBlH9Tz2KZg5MQ',
} as const;

export default LINKS;
