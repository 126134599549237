import { useEffect, useState } from 'react';
import styled, { useTheme, css } from 'styled-components';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';
import { pluralize } from '../../utils';
import Button from './base/Button';

type JSONPrimitive = string | number | boolean | null;

type JSONValue =
  | JSONPrimitive
  | (JSONPrimitive | JSONValue)[]
  | { [key: string]: JSONPrimitive | JSONValue };

const isPrimitive = (value: JSONValue) => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null
  );
};

const StyledKey = styled.span<{ compact: boolean }>`
  font-size: ${({ theme, compact }) => theme.pxToRem(compact ? 12 : 13)};
  font-family:
    JetBrains Mono,
    monospace;
`;

const StyledValue = styled.span<{ type: JSONPrimitive; compact: boolean }>(
  ({ theme, type, compact }) => css`
    color: ${theme.colors.on.code[String(type)]};
    font-size: ${theme.pxToRem(compact ? 12 : 13)};
    font-family:
      JetBrains Mono,
      monospace;
  `,
);
const StyledHint = styled.span<{
  count?: number;
  disable_selection?: boolean;
  closed?: boolean;
  compact: boolean;
}>(
  ({ theme, count, disable_selection, closed, compact }) => css`
    color: ${theme.colors.on.neutral.primary_neutral};
    margin: 0;
    border: none;
    padding: ${theme.spacing(0.25)};
    background-color: transparent;
    font-size: ${theme.pxToRem(compact ? 12 : 13)};

    ${disable_selection
      ? css`
          user-select: none;
        `
      : css`
          padding: ${theme.spacing(0.25)};
          border-radius: ${theme.radius.small};
          &:hover {
            background-color: ${theme.colors.surface.base.variant_surface};
          }
        `}
    ${count !== undefined &&
    css`
      &::after {
        content: ${count === 0
          ? '"Empty"'
          : `"${count} ${pluralize(count, 'item')} ${closed ? '↓' : '↑'}"`};
        display: inline-block;
        user-select: none;
        margin-left: ${theme.spacing(1)};
        font-size: ${theme.pxToRem(compact ? 11 : 12)};
        color: ${theme.colors.on.neutral.secondary_neutral};
        font-family:
          JetBrains Mono,
          monospace;
      }
    `}
  `,
);

const StyledCollapseButton = styled(Button)<{ compact: boolean }>(
  ({ theme, compact }) => css`
    position: absolute;
    top: 0;
    right: 0;

    color: ${theme.colors.on.neutral.primary_neutral};
    border: none;
    background-color: transparent;
    font-size: ${theme.pxToRem(compact ? 11 : 12)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    margin: ${theme.spacing(-0.5)} ${theme.spacing(-2)};
    font-weight: normal;

    &:focus {
      outline: none;
      background-color: transparent;
    }
  `,
);

const StyledLine = styled.span<{ depth: number }>(
  ({ theme, depth }) => css`
    display: block;
    padding-left: ${theme.spacing((0.5 * depth + 2) as any)};
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    overflow-wrap: anywhere;
  `,
);

const HighlightSearchTerm: React.FC<{
  Component: any;
  value: string;
  compact: boolean;
  search_term: string;
  props?: object;
}> = ({ Component, value, search_term, props, compact }) => {
  const theme = useTheme();
  if (!search_term) {
    return <Component {...(props || {})}>{value}</Component>;
  }
  const parts = value.split(new RegExp(`(${search_term})`, 'g'));
  return (
    <>
      {parts.map((p, i) => (
        <Component
          {...(props || {})}
          compact={compact}
          key={i}
          style={
            search_term === p ? { backgroundColor: theme.colors.surface.container.highlight } : {}
          }>
          {p}
        </Component>
      ))}
    </>
  );
};

const PrimitiveComponent: React.FC<{
  value: JSONPrimitive;
  search_term: string;
  compact: boolean;
}> = ({ value, search_term, compact }) => {
  let type = 'string';
  if (typeof value === 'number') {
    type = 'number';
  } else if (typeof value === 'boolean') {
    type = 'boolean';
  } else if (value === null) {
    type = 'null';
  }

  return (
    <HighlightSearchTerm
      compact={compact}
      search_term={search_term}
      Component={StyledValue}
      props={{ type }}
      value={type === 'string' ? `"${value}"` : String(value)}
    />
  );
};

type CollapseStatus = 'collapsed' | 'expanded' | null;

const RecursiveJsonComponent: React.FC<{
  search_term: string;
  search_results: boolean | object | undefined;
  json: JSONValue;
  depth: number;
  compact: boolean;
  collapse_status: CollapseStatus;
  setCollapse: (status: CollapseStatus, last_action: 'collapse' | 'expand') => void;
}> = ({ search_results, search_term, json, depth, collapse_status, setCollapse, compact }) => {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (!isPrimitive(json)) {
      if (collapse_status === 'expanded') {
        setClosed(false);
      } else if (collapse_status === 'collapsed') {
        setClosed(true);
      }
    }
  }, [collapse_status, json]);

  if (isPrimitive(json)) {
    return (
      <PrimitiveComponent
        search_term={search_term}
        value={json as JSONPrimitive}
        compact={compact}
      />
    );
  }

  const is_array = Array.isArray(json);
  const entries: [number | string, JSONValue][] = is_array
    ? json.map((value: JSONValue, i) => [i, value])
    : Object.entries(json!);

  if (entries.length === 0) {
    return (
      <StyledHint compact={compact} count={0}>
        {is_array ? '[ ]' : '{ }'}
      </StyledHint>
    );
  }

  const onClick = (e) => {
    e.stopPropagation();
    if (!isPrimitive(json)) {
      setCollapse(collapse_status, !closed ? 'collapse' : 'expand');
      setClosed((closed) => !closed);
    }
  };

  if (closed) {
    return (
      <StyledHint
        as="button"
        disable_selection
        compact={compact}
        count={entries.length}
        closed={true}
        onClick={onClick}>
        {is_array ? ' [...]' : ' {...}'}
      </StyledHint>
    );
  }

  return (
    <>
      <StyledHint
        as="button"
        compact={compact}
        count={entries.length}
        {...(entries.length > 0
          ? {
              onClick: onClick,
            }
          : {})}>
        {is_array ? '[' : '{'}
      </StyledHint>
      {entries.map(([key, value], i) => {
        if (search_results && !search_results[i]) {
          return null;
        }

        return (
          <StyledLine key={key} depth={depth + 1}>
            {is_array ? (
              <StyledHint compact={compact} disable_selection>
                {key}:{' '}
              </StyledHint>
            ) : (
              <HighlightSearchTerm
                Component={StyledKey}
                compact={compact}
                search_term={search_term}
                value={`"${key}": `}
              />
            )}
            <RecursiveJsonComponent
              compact={compact}
              search_results={
                !search_results || (!is_array && String(key).indexOf(search_term) >= 0)
                  ? undefined
                  : search_results[i]
              }
              search_term={search_term}
              json={value}
              depth={depth + 1}
              collapse_status={collapse_status}
              setCollapse={setCollapse}
            />
            {i !== entries.length - 1 && <StyledHint compact={compact}>,</StyledHint>}
          </StyledLine>
        );
      })}
      <StyledHint compact={compact}>{is_array ? ']' : '}'}</StyledHint>
    </>
  );
};

const recursivelyBuildSearchResults = (
  search_term: string,
  json: JSONValue,
  results?: object,
): object | boolean | undefined => {
  if (isPrimitive(json)) {
    if (String(json).indexOf(search_term) >= 0) {
      return true;
    }
    return undefined;
  }
  results = {};
  let found_one = false;
  const array = Array.isArray(json) ? json : Object.entries(json!);
  array.forEach((line, i) => {
    const [key, value] = Array.isArray(line) ? line : [null, line];
    if (key && String(key).indexOf(search_term) >= 0) {
      results![i] = true;
      found_one = true;
    }
    const recursive_result = recursivelyBuildSearchResults(search_term, value, results![i]);
    if (recursive_result) {
      results![i] = recursive_result;
      found_one = true;
    }
  });
  return found_one ? results : undefined;
};

const JSONBrowser: React.FC<{
  search_term?: string;
  json: JSONValue;
  compact?: boolean;
}> = ({ search_term, json, compact }) => {
  const search_results =
    search_term && search_term?.length > 1
      ? recursivelyBuildSearchResults(search_term, json)
      : undefined;
  const [collapse_status, setCollapseStatus] = useState<CollapseStatus>('expanded');
  const [last_action, setLastAction] = useState<'collapse' | 'expand' | null>(null);

  const setCollapse = (status: CollapseStatus, last_action: 'collapse' | 'expand') => {
    setCollapseStatus(null);
    setLastAction(last_action);
  };

  if (search_term && search_term?.length > 1 && !search_results) {
    return <Text muted>No match found.</Text>;
  }

  let collapse_action = 'collapsed';
  if (collapse_status === 'collapsed' || last_action === 'collapse') {
    collapse_action = 'expanded';
  }
  return (
    <Div style={{ position: 'relative' }}>
      <RecursiveJsonComponent
        compact={!!compact}
        search_term={search_term && search_term?.length > 1 ? search_term : ''}
        search_results={search_results}
        json={json}
        depth={0}
        collapse_status={collapse_status}
        setCollapse={setCollapse}
      />
      {!isPrimitive(json) && (
        <StyledCollapseButton
          compact={!!compact}
          minimal
          onClick={() => {
            setCollapseStatus(collapse_action !== 'collapsed' ? 'expanded' : 'collapsed');
            setLastAction(collapse_action !== 'collapsed' ? 'expand' : 'collapse');
          }}>
          {collapse_action !== 'collapsed' ? 'Expand All ↓' : 'Collapse All ↑'}
        </StyledCollapseButton>
      )}
    </Div>
  );
};

export default JSONBrowser;
