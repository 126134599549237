import { useContext } from 'react';
import { Destination } from '../../../../../typings/Destination.interface';
import { ShortEventData } from '../../../../../typings/EventData.interface';
import { copyTextToClipboard } from '../../utils/copy';
import { useToasts } from '../common/Toast';
import { GlobalContext } from '../contexts/GlobalContext';

const useCopyASCurl = ({
  type,
  id,
  event_data,
  destination,
}: {
  type?: 'request' | 'event';
  id?: string;
  event_data?: ShortEventData;
  destination?: Destination;
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  return async () => {
    const raw_body =
      type && id && ['event', 'request'].includes(type)
        ? (await HookdeckAPI[`${type}s`].getRawBody(id)).body
        : JSON.stringify(event_data?.body);

    let url = 'https://mock.hookdeck.com';
    if (destination?.url) {
      url = destination.url;
    } else if (destination?.cli_path) {
      url = `http://localhost:[PORT]${destination.cli_path}`;
    }
    if (event_data?.path && event_data?.path !== '/') {
      url = `${url}${event_data?.path}`;
    }
    if (event_data?.query) {
      url = `${url}?${event_data?.query}`;
    }
    const curl_snippet_lines = [`curl "${url}"`] as string[];
    curl_snippet_lines.push(
      `-X ${(event_data?.headers['x-hookdeck-original-method'] || 'POST').toUpperCase()}`,
    );
    Object.entries(event_data?.headers ?? {}).forEach(([key, value]) => {
      curl_snippet_lines.push(`-H "${key}: ${value}"`);
    });
    curl_snippet_lines.push(`-d "${raw_body.replace(/"/g, '\\"')}"`);
    const curl_snippet = curl_snippet_lines.join(' \\\n');

    const success = await copyTextToClipboard(curl_snippet);
    if (success) {
      addToast('success', 'Copied cURL command to clipboard');
    }
  };
};

export default useCopyASCurl;
