import { FC, MouseEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';

import * as PopperJS from '@popperjs/core';

import { copyTextToClipboard } from '../../../utils/copy';
import { Div } from '../helpers/StyledUtils';
import { useToasts } from '../Toast';
import Button from './Button';
import Icon, { IconName } from './Icon';
import Text from './Text';

const StyledTooltipWrapper = styled.div`
  z-index: ${({ theme }) => theme.zindex.tooltip};
`;

const StyledTooltipContainer = styled.div`
  line-height: initial;
  svg {
    vertical-align: text-top;
  }
`;

const StyledTooltip = styled.div(
  ({ theme }) => css`
    max-width: 344px;
    word-break: break-word;
    white-space: initial;
    width: max-content;
    background-color: ${theme.colors.surface.base.surface};
    border: ${theme.border};
    box-shadow: ${theme.elevation[3]};
    border-radius: ${theme.radius.normal};
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
  `,
);

const Tooltip: FC<{
  align?: 'right' | 'left';
  disabled?: boolean;
  placement?: PopperJS.Placement;
  tooltip: ReactNode;
  children: ReactNode;
  copyable?: boolean;
  offset?: [number, number];
  cta?: {
    icon: IconName;
    label: string;
    to: string;
  };
}> = ({ tooltip, placement = 'auto', align, children, copyable, disabled, offset, cta }) => {
  const [hovered, setHovered] = useState(false);
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const { addToast } = useToasts();

  const tooltipContainer = useMemo(() => {
    if (typeof document === 'undefined') return undefined;
    if (!referenceElement.current) return undefined;
    if (referenceElement.current.getRootNode?.() instanceof ShadowRoot) {
      return referenceElement.current.getRootNode() as HTMLElement;
    }
    return document.body;
    // ignore this as we do want to pass referenceElement.current
    // so it will re-run the tooltip container logic once the ref is attached to the HTML element
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceElement.current]);

  const handleCopyToClipboard = (e: MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();

    copyTextToClipboard(String(tooltip)).then((success) =>
      success
        ? addToast('success', `Copied '${String(tooltip)}' to clipboard`)
        : addToast('error', 'Failed to copy'),
    );
  };

  if (align === 'right') {
    placement = 'bottom-end';
  } else if (align === 'left') {
    placement = 'bottom-start';
  }

  if (!offset && placement.indexOf('bottom') >= 0) {
    offset = [0, 2];
  }

  const { styles, attributes, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 8,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['auto'],
          },
        },
        {
          name: 'offset',
          options: {
            offset,
          },
        },
      ],
    },
  );

  /**
   * We should update Popper position when to ensure its position is correct even
   * after some layout shift (sidebar created)
   *
   * Example: "received at" column tooltip in requests page after clicking on it to open the request sidebar
   */
  const createHoverEnterHandler = (shouldUpdate: boolean) => () => {
    setHovered(true);
    // avoid updating when a tooltip is showing (hovered is true)
    shouldUpdate && !hovered && update?.();
  };
  const onHoverLeave = () => {
    setHovered(false);
  };

  if (disabled) {
    return <>{children}</>;
  }

  const tooltipElement = (
    <StyledTooltipWrapper
      onMouseLeave={onHoverLeave}
      onMouseEnter={createHoverEnterHandler(false)}
      ref={popperElement}
      style={styles.popper}
      {...attributes.popper}>
      {hovered && (
        <StyledTooltip onClick={copyable ? handleCopyToClipboard : undefined}>
          <Div
            flex={{ direction: 'column', justify: 'space-between', gap: 2, align: 'center' }}
            m={{ t: cta ? 1.5 : 0 }}>
            <Text>{tooltip}</Text>
            {copyable && <Icon icon="copy" right />}
          </Div>
          {cta && (
            <Button block primary outline to={cta.to} icon={cta.icon} m={{ t: 2, b: 1.5 }}>
              {cta.label}
            </Button>
          )}
        </StyledTooltip>
      )}
    </StyledTooltipWrapper>
  );

  return (
    <>
      <StyledTooltipContainer
        onMouseLeave={onHoverLeave}
        onMouseEnter={createHoverEnterHandler(true)}
        ref={referenceElement}>
        {children}
      </StyledTooltipContainer>
      {tooltipContainer ? createPortal(tooltipElement, tooltipContainer) : tooltipElement}
    </>
  );
};

export default Tooltip;
